import { useContext, useEffect, useState, Fragment } from "react";
import { Tab, Dialog, Transition } from "@headlessui/react";
//
import UserObject from "../../DataObjects/UserObject";
import http from "../../Store/baseHttp";
import ResponseObject from "../../DataObjects/ResponseObject";
import {
  AiOutlineClose,
  AiOutlineEyeInvisible,
  AiOutlinePlus,
} from "react-icons/ai";
import ToastContext from "../../Routes/ToastContext";
import { usePaystackPayment } from "react-paystack";
import WithdrawlForm from "./cooperative/withdrawalForm";
import { formatNaira } from "../Utils/FormatMoney";
import { PAYSTACK_KEY } from "../Utils/PaystackFunctions";
import walletimage from "../../Assets/dashboard/EmptyStatewallet.svg";

export const WalletCard = ({ balance }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isWithdrawalOpen, setIsWithdrawalOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const { paystack_customer_account } = UserObject.getUser();

  // const [status, setStatus]= useState=("")
  const handleClick = () => {
    setIsVisible(!isVisible);
  };
  const toggleWithdrawalModal = () => {
    setIsWithdrawalOpen((prev) => !prev);
  };
  return (
    <div>
      <div className="h-[230px] lg:w-[60%] xl:w-[45%] flex flex-col gap-y-6 justify-between bg-gradient-to-r from-[#FAB2FF] to-[#6704E5] text-white rounded-[8px] pt-[20px] pb-[24px] px-[20px]">
        <div>
          <h2 className="font-bold leading-[24px]">Fedacash Cooperative</h2>
          <div>
            <div className="font-semibold text-4xl leading-[32.5px] tracking-[-2%] flex items-center italic space-x-2 ">
              {isVisible
                ? formatNaira(balance?.data?.available_balance ?? 0)
                : "*****"}
              <div onClick={handleClick}>
                <AiOutlineEyeInvisible size={32} />
              </div>{" "}
            </div>
            <div className="flex items-center gap-x-4 text-sm lg:text-base">
              <p>Book balance:</p>
              <div className="font-semibold leading-8">
                {isVisible
                  ? formatNaira(balance?.data?.book_balance ?? 0)
                  : "*****"}
              </div>
            </div>
            <div>
              <div className="flex items-center gap-x-4 text-sm lg:text-base">
                <p>Account Number:</p>
                <div className="font-semibold">
                  {paystack_customer_account
                    ? paystack_customer_account
                    : "*****"}
                </div>
              </div>
              <p>Bank Name</p>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <FundWallet />
          <WithdrawlForm />
        </div>
      </div>
    </div>
  );
};

const FundWallet = () => {
  const { flashToast } = useContext(ToastContext);

  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState("idle"); //three status - idle/ processing/completed
  const [message, setMessage] = useState({});

  const [deposit, setDeposit] = useState("");
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  async function fundWallet(data) {
    try {
      let response = await http.post("/cooperative/add/wallet", data);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }
  const paystackCharges =
    deposit > 1999 ? deposit * 0.015 + 100 : deposit * 0.015;
  const finalAmount = parseFloat(deposit) + parseFloat(paystackCharges);
  const config = {
    reference: new Date().getTime().toString(),
    email:
      UserObject.getUser().email ??
      `${UserObject.getUser().phone_number}@fedacash.com`,
    amount: parseInt(finalAmount * 100), //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: PAYSTACK_KEY,
    metadata: {
      module: "cooperative",
      product: "save and pack",
      payment_name: "Wallet Funding",
      payment_description: "Save and pack wallet funding",
      customer_email: UserObject.getUser().email ?? "",
      phone_number: UserObject.getUser().phone_number ?? "",
    },
  };
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    fundWallet({
      reference: reference?.reference,
      amount: parseFloat(deposit),
    }).then((res) => {
      setMessage(res)
      closeModal();
      flashToast(res?.success, res?.message);
    }).catch((res)=>{
      closeModal();
      flashToast(res?.success, res?.message);
    });
  };

  // you can call this function anything
  const onPaystackClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log("closed");
  };
  const initializePayment = usePaystackPayment(config);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (message?.success) {
        // Refresh the page with React Router in a few seconds
        window.location.reload();
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [message?.success]);
  return (
    <div>
      <button
        onClick={openModal}
        name="fund wallet"
        className="flex items-center py-2 px-5 space-x-2 bg-[#120321] text-white rounded-lg  font-semibold text-xs md:text-sm  leading-[21px] "
      >
        <AiOutlinePlus />
        <span>Fund</span>
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full rounded-md max-w-xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex gap-3 justify-evenly text-lg font-bold leading-6 text-[#475467]"
                  >
                    Fund Your Wallet
                    <button
                      type="button"
                      className=""
                      onClick={() => closeModal()}
                    >
                      <AiOutlineClose />
                    </button>
                  </Dialog.Title>
                  <img src={walletimage} alt="" />

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      initializePayment(onSuccess, onPaystackClose);
                    }}
                    className="py-4 space-y-3"
                  >
                    <div className="space-y-2">
                      <label>Enter Amount</label>
                      <input
                        placeholder="Amount"
                        value={deposit}
                        required
                        type="number"
                        onChange={(e) => setDeposit(e.target.value)}
                        className="font-bold w-full text-[12px] leading-[18px] py-[11px] px-[19px] placeholder:text-[#b8c0cc] border-[1.5px] rounded-[8px] border-[#b8c0cc] focus:outline-none"
                      />
                    </div>
                    <div className="mt-4">
                      <button className="text-white bg-fedacash-header text-center w-full py-2 md:py-3 md:px-2 rounded-md text-sm lg:textbase">
                        Proceed to payment
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
