import { useState, Fragment, Suspense, useContext } from "react";
import { BiPaperPlane } from "react-icons/bi";
import { Tab, Dialog, Transition } from "@headlessui/react";
import {
  AiOutlineClose,
  AiOutlineEyeInvisible,
  AiOutlineLeft,
  AiOutlinePlus,
} from "react-icons/ai";
import { Await, Form, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

///file import
import paymentImage from "../../../Assets/images/saveandpack.png";
import emptyState from "../../../Assets/images/empty-state.png";
import walletimage from "../../../Assets/dashboard/EmptyStatewallet.svg";

import UserObject from "../../../DataObjects/UserObject";
import {
  formatAsNaira,
  formatNaira,
} from "../../../Components/Utils/FormatMoney";
import { usePaystackPayment } from "react-paystack";
import {
  JoinPlan,
  PlanlistRequestQuery,
} from "../../../Services/Dashboard/SaveandGo";
import { StatusDialog } from "../../../Components/Dashboard/Shared/StatusDialog";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import http from "../../../Store/baseHttp";
import ResponseObject from "../../../DataObjects/ResponseObject";
import Panel from "../../../Components/Dashboard/Panel";
import useTable from "../../../Components/Dashboard/Table/useTable";
import usePagination from "../../../Components/Dashboard/Table/usePagination";
import moment from "moment";
import useForm from "../../../Hooks/useForm";
import Banks from "../Dashboard/Banks";
import WithdrawlForm from "../../../Components/Dashboard/cooperative/withdrawalForm";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { RenewPlan } from "../../../Components/Dashboard/cooperative/RenewalButton";
import Submit from "../../../Components/Forms/Submit";
import { Spinner } from "flowbite-react";
import ToastContext from "../../../Routes/ToastContext";
import { WalletCard } from "../../../Components/Dashboard/WalletCard";
const SaveandPack = () => {
  const { data } = useQuery(PlanlistRequestQuery());
console.log("query data",data)
// data.walletBalance
  return (
    <section className="space-y-4">
      <div className="">
        <WalletCard balance={data.walletBalance}/>
      </div>
      <div className="tabs mt-4 md:mt-8 lg:mt-12">
        <Tab.Group>
          <Tab.List
            className={`flex border justify-evenly h-12 text-xs md:text-base w-full bg-white font-bold`}
          >
            <Tab
              className={`ui-selected:border-fedacash-header ui-selected:border-b-2 w-1/3`}
            >
              Overview
            </Tab>
            <Tab
              className={`ui-selected:border-fedacash-header ui-selected:border-b-2 w-1/3`}
            >
              Subscriptions
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className={`overview`}>
              <section>
                <Overview />
              </section>
            </Tab.Panel>
            <Tab.Panel>
              <section className="">
                <Subscriptions />
              </section>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
        {/* <ViewPlanModal isViewOpen={isViewOpen} closeModal={closeViewModal} /> */}
      </div>
    </section>
  );
};

export default SaveandPack;

const Overview = () => {
  // const data = useLoaderData();
  const { data, isLoading } = useQuery(PlanlistRequestQuery());
  return (
    <Panel>
      <div className="">
        <div className="flex gap-x-3 justify-end">
          <MultiStepForm />
          {data?.LoanStatus?.data ? (
            <Link
              // onClick={openModal}
              to={`/dashboard/loan/request`}
              className="flex items-center gap-2 mt-2 text-sm bg-black p-2 text-white rounded-md"
            >
              <AiOutlinePlus />
              <span>Access Loan</span>
            </Link>
          ) : (
            ""
          )}
        </div>
        {data?.planListResponse?.data?.data?.length === 0 ? (
          <EmptyState />
        ) : (
          <TransactionHistory />
        )}
      </div>
    </Panel>
  );
};
const EmptyState = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal(name) {
    setIsOpen(true);
  }
  return (
    <section>
      <div className="flex flex-col items-center justify-center py-12">
        <div className="flex flex-col justify-center items-center text-center max-w-xl">
          <h3></h3>
          <p className="">
            Dear {UserObject.getUser()?.first_name}... You're just a few steps
            away from becoming a part of our Cooperative Savings Community! To
            kickstart your journey towards smarter financial management and
            secure savings
          </p>
          <MultiStepForm closeModal={closeModal} isOpen={isOpen} />
        </div>
        <img
          src={emptyState}
          alt=""
          className="w-56 h-56"
          width={224}
          height={224}
        />
      </div>
    </section>
  );
};
const TransactionHistory = () => {
  const { data } = useQuery(PlanlistRequestQuery());
  const { Column, ColumnHeader, Row, RowHeader, Table } = useTable();
  const {
    Pagination,
    Button,
    Prev,
    Next,
    setPage,
    searchParams,
    PageSize,
    Description,
  } = usePagination();
  //I wrote another function because the general function kept returning a null error
  const transactionHistorydata = data?.planListResponse?.data?.data;
  const transactionPagination = data?.planListResponse?.data;
  return (
    <div className="w-full p-[15px] rounded-[16px] bg-white overflow-x-auto">
      <Table>
        <RowHeader>
          <ColumnHeader>ID</ColumnHeader>
          <ColumnHeader>Reference</ColumnHeader>
          <ColumnHeader>Status</ColumnHeader>
          <ColumnHeader>Amount</ColumnHeader>
          <ColumnHeader>Date</ColumnHeader>
          <ColumnHeader>Plan</ColumnHeader>

          {/* Add more table headers as needed */}
        </RowHeader>
        <tbody className="divide-y-[1.5px]">
          {transactionHistorydata?.length > 0 ? (
            transactionHistorydata
              .sort((a, b) => a.transaction_date - b.transaction_date)
              .map((item) => (
                <Row key={item.id}>
                  <Column>{item.id}</Column>
                  <Column>{item.reference}</Column>
                  <Column className="capitalize">{item.status ?? "N/A"}</Column>
                  <Column>
                    {formatAsNaira(item?.cooperative_amount) ?? "N/A"}
                  </Column>
                  <Column>
                    {moment(item.transaction_date).format(
                      "dddd, Do MMMM YYYY: h:mmA"
                    )}
                  </Column>
                  <Column>{item?.package_name ?? "N/A"}</Column>
                </Row>
              ))
          ) : (
            <tr>
              <td colSpan="4">No data available</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Pagination>
        <>
          <Prev
            onClick={() => {
              setPage(1);
            }}
          />
          {Array(transactionPagination?.last_page)
            .fill(5)
            .map((v, i) => {
              return (
                <Button
                  key={i}
                  isActive={transactionPagination?.current_page === i + 1}
                  onClick={() => {
                    setPage(i + 1);
                  }}
                >
                  {i + 1}
                </Button>
              );
            })}
          <Next
            onClick={() => {
              setPage(transactionPagination?.last_page);
            }}
          />
        </>
      </Pagination>
    </div>
  );
};
const Subscriptions = () => {
  const { data } = useQuery(PlanlistRequestQuery());
  const { Column, ColumnHeader, Row, RowHeader, Table } = useTable();
  const {
    Pagination,
    Button,
    Prev,
    Next,
    setPage,
    searchParams,
    PageSize,
    Description,
  } = usePagination();
  const subscriptionsData = data?.subscriptions?.data;
  return (
    <div className="w-full p-[15px] rounded-[16px] bg-white overflow-x-auto">
      <Table>
        <RowHeader>
          <ColumnHeader>ID</ColumnHeader>
          <ColumnHeader>Start Date</ColumnHeader>
          <ColumnHeader>End Date</ColumnHeader>
          <ColumnHeader>Amount</ColumnHeader>
          <ColumnHeader>Plan</ColumnHeader>
          <ColumnHeader>Renew</ColumnHeader>

          {/* Add more table headers as needed */}
        </RowHeader>
        <tbody className="divide-y-[1.5px]">
          {subscriptionsData?.data?.length > 0 ? (
            subscriptionsData?.data?.map((item) => (
              <Row key={item.id}>
                <Column>{item.id}</Column>
                <Column>
                  {moment(item.created_at).format("dddd, Do MMMM YYYY: h:mmA")}
                </Column>
                <Column className="capitalize">
                  {moment(item.end_date).format("dddd, Do MMMM YYYY: h:mmA")}
                </Column>
                <Column>
                  {formatAsNaira(item?.amount_to_contribute) ?? "N/A"}
                </Column>
                <Column>{item.description}</Column>
                <Column>
                  <RenewPlan item={item} />
                </Column>
              </Row>
            ))
          ) : (
            <tr>
              <td colSpan="4">No data available</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Pagination>
        <>
          <Prev
            onClick={() => {
              setPage(1);
            }}
          />
          {Array(subscriptionsData?.last_page)
            .fill(5)
            .map((v, i) => {
              return (
                <Button
                  key={i}
                  isActive={subscriptionsData?.current_page === i + 1}
                  onClick={() => {
                    setPage(i + 1);
                  }}
                >
                  {i + 1}
                </Button>
              );
            })}
          <Next
            onClick={() => {
              setPage(subscriptionsData?.last_page);
            }}
          />
        </>
      </Pagination>
    </div>
  );
};
// const WalletCard = () => {
//   const [isVisible, setIsVisible] = useState(true);
//   const [isWithdrawalOpen, setIsWithdrawalOpen] = useState(false);
//   const [checked, setChecked] = useState(true);
//   const { data } = useQuery(PlanlistRequestQuery());
//   const { paystack_customer_account } = UserObject.getUser();

//   // const [status, setStatus]= useState=("")
//   const handleClick = () => {
//     setIsVisible(!isVisible);
//   };
//   const toggleWithdrawalModal = () => {
//     setIsWithdrawalOpen((prev) => !prev);
//   };
//   return (
//     <div>
//       <div className="h-[230px] lg:w-[60%] xl:w-[45%] flex flex-col gap-y-6 justify-between bg-gradient-to-r from-[#FAB2FF] to-[#6704E5] text-white rounded-[8px] pt-[20px] pb-[24px] px-[20px]">
//         <div>
//           <h2 className="font-bold leading-[24px]">Fedacash Cooperative</h2>
//           <Suspense loading={<div>Loading...</div>}>
//             <Await resolve={data}>
//               {(userCooperativeObject) => {
//                 const { walletBalance } = userCooperativeObject;

//                 return (
//                   <div>
//                     <div className="font-semibold text-4xl leading-[32.5px] tracking-[-2%] flex items-center italic space-x-2 ">
//                       {isVisible
//                         ? formatNaira(
//                             walletBalance?.data?.available_balance ?? 0
//                           )
//                         : "*****"}
//                       <div onClick={handleClick}>
//                         <AiOutlineEyeInvisible size={32} />
//                       </div>{" "}
//                     </div>
//                     <div className="flex items-center gap-x-4 text-sm lg:text-base">
//                       <p>Book balance:</p>
//                       <div className="font-semibold leading-8">
//                         {isVisible
//                           ? formatNaira(walletBalance?.data?.book_balance ?? 0)
//                           : "*****"}
//                       </div>
//                     </div>
//                     <div>
//                       <div className="flex items-center gap-x-4 text-sm lg:text-base">
//                         <p>Account Number:</p>
//                         <div className="font-semibold">
//                           {paystack_customer_account
//                             ? paystack_customer_account
//                             : "*****"}
//                         </div>
//                       </div>
//                       <p>Bank Name</p>
//                     </div>
//                   </div>
//                 );
//               }}
//             </Await>
//           </Suspense>
//         </div>
//         <div className="flex justify-between items-center">
//           <FundWallet />
//           <WithdrawlForm />
//         </div>
//       </div>
//     </div>
//   );
// };

const Description = ({ value, onChange, nextPage }) => {
  //validations for the component
  const validateDescription = () => {
    if (value === "") {
      return "Description is required.";
    }
    nextPage();
  };
  return (
    <div className="text-[#98A2B3] flex flex-col gap-y-4">
      <h4 className="font-bold">
        What are you saving for? Add a short description to make your plan
        memorable.
      </h4>
      <div>
        <label className="flex flex-col" htmlFor="">
          <span className="font-medium"> Saving Description</span>
          <input
            type="text"
            name="description"
            value={value}
            required
            onChange={(e) => onChange(e.target.name, e.target.value)}
            className="text-black mt-3 rounded border border-[#98A2B3] placeholder:text-sm placeholder:text-[#667085] font-medium"
            placeholder="Enter Description"
          />
        </label>
      </div>
      <button
        onClick={validateDescription}
        className="w-full bg-fedacash-header px-2 py-[10px] text-white rounded-md text-center"
      >
        Continue
      </button>
    </div>
  );
};

const Amount = ({ onChange, nextPage }) => {
  const [selectedAmount, setSelectedAmount] = useState({
    amount: "",
    select: "default",
  });
  const minimumAmount = 2000;
  const formattedAmount = `₦${minimumAmount}`;
  const [customAmount, setCustomAmount] = useState("");
  const [error, setError] = useState("");
  const validateAmount = () => {
    if (selectedAmount.amount === "") {
      setError("Amount is required.");
    } else if (selectedAmount.amount.includes("-")) {
      setError("You can't add a negative value");
    } else if (selectedAmount.amount < 2000) {
      setError(`Minimum amount is ${formattedAmount}`);
    } else {
      nextPage();
    }
  };
  const handleAmountClick = (amount, select) => {
    setSelectedAmount({ amount: amount, select: select });
    setCustomAmount("");
    onChange("amount", amount);
  };

  const amountButtons = [
    { amount: 10000, label: "10,000" },
    { amount: 15000, label: "15,000" },
    { amount: 20000, label: "20,000" },
    { amount: 30000, label: "30,000" },
    { amount: 50000, label: "50,000" },
  ];

  return (
    <div className="text-[#98A2B3] flex flex-col gap-y-4">
      <h4 className="font-bold">Good Name! How much will you start with?</h4>
      <div className="flex flex-wrap gap-x-10 gap-y-4 items-center justify-center">
        {amountButtons.map((button) => (
          <button
            key={button.amount}
            className={`text-fedacash-header font-semibold px-4 py-2 text-lg rounded-full ${
              selectedAmount.amount === button.amount.toString()
                ? "bg-[#DECFED]"
                : "bg-[#F6F8FA]"
            }`}
            onClick={() =>
              handleAmountClick(button.amount.toString(), "default")
            }
          >
            &#8358;{button.label}
          </button>
        ))}
        <button
          className={`text-fedacash-header font-semibold px-4 py-2 text-lg rounded-full ${
            selectedAmount.select === "custom" && "bg-[#DECFED]"
          }`}
          onClick={() => handleAmountClick("", "custom")}
        >
          Different amount
        </button>
      </div>
      {selectedAmount.select === "custom" && (
        <div className="flex flex-col gap-y-2">
          <p className="text-[#98A2B3] text-sm font-medium">Enter Amount</p>
          <input
            type="number"
            value={selectedAmount.amount}
            className="text-black rounded border border-[#98A2B3] placeholder:text-sm placeholder:text-[#667085] font-medium"
            placeholder="Amount"
            onChange={(e) => handleAmountClick(e.target.value, "custom")}
          />
        </div>
      )}
      <p className="text-red-700"> {error}</p>
      <button
        onClick={validateAmount}
        className="w-full bg-fedacash-header px-2 py-[10px] text-white rounded-md text-center"
      >
        Continue
      </button>
    </div>
  );
};

const FrequencySelection = ({ amountValue, onChange, nextPage }) => {
  const [selectedFrequency, setSelectedFrequency] = useState("");
  const [error, setError] = useState("");

  const validatefrequencySelection = () => {
    if (selectedFrequency === "") {
      setError("Please select a frequency");
    } else {
      setError("");
      nextPage();
    }
  };
  const handleFrequencyClick = (frequency) => {
    setSelectedFrequency(frequency);
    onChange("frequencySelection", frequency);
  };

  const frequencyOptions = [
    "daily",
    "weekly",
    "monthly",
    // "quarterly",
    "annually",
  ];
  if (amountValue < 5000) {
    frequencyOptions.splice(2, 2);
  }
  return (
    <div>
      <h4 className="font-bold">
        How Often Would you want to Contribute &#8358;{amountValue}
      </h4>
      <div className="flex flex-wrap gap-x-10 gap-y-4 items-center justify-center">
        {frequencyOptions.map((option) => (
          <button
            key={option}
            className={`text-fedacash-header font-semibold px-4 py-2 text-lg rounded-full capitalize ${
              selectedFrequency === option ? "bg-[#DECFED]" : "bg-[#F6F8FA]"
            }`}
            onClick={() => handleFrequencyClick(option)}
          >
            {option}
          </button>
        ))}
      </div>
      <div className="text-[#98A2B3] flex justify-between">
        <p>FrequencySelection Selected</p>
        <p className="capitalize">{selectedFrequency}</p>
      </div>
      <p className="text-red-700 mb-6"> {error}</p>
      <button
        onClick={validatefrequencySelection}
        className="w-full bg-fedacash-header px-2 py-[10px] text-white rounded-md text-center"
      >
        Continue
      </button>
    </div>
  );
};

const Summary = ({ values, onChange }) => (
  <div>
    <div className="mt-4 text-sm font-medium divide-y divide-black">
      <div className="flex justify-between py-2">
        <p>Description</p>
        <p>{values.description}</p>
      </div>
      <div className="flex justify-between py-2">
        <p>Amount</p>
        <p>{formatNaira(values.amount)}</p>
      </div>
      <div className="flex justify-between py-2">
        <p>Frequency</p>
        <p>{values.frequencySelection}</p>
      </div>
      <div className="flex justify-between py-2">
        <p>Charges</p>
        <p>
          {formatNaira(
            values.amount > 1999
              ? values.amount * 0.015 + 100
              : values.amount * 0.015
          )}
        </p>
      </div>
      <div className="flex justify-between py-2">
        <p>
          <Link
            to={`https://www.fedacash.com/terms-and-conditions`}
            target="_blank"
            className="text-fedacash-header underline"
          >
            Terms
          </Link>
        </p>
      </div>
    </div>
  </div>
);

const FrequencyDuration = ({ frequencyvalue, setPage, nextPage, onChange }) => {
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [error, setError] = useState("");
  const durations = [
    { name: "1 month", value: 1 },
    { name: "2 months", value: 2 },
    { name: "3 months", value: 3 },
    { name: "6 months", value: 6 },
    { name: "1 Year", value: 12 },
  ];
  const validatefrequencyDuration = () => {
    if (selectedDuration === "") {
      setError("Please select a duration");
    } else {
      setError("");
      nextPage();
    }
  };
  const handleDurationClick = (duration) => {
    setSelectedDuration(duration);
    onChange("frequencyDuration", duration);
    // console.log(duration)
  };

  if (frequencyvalue === "once") {
    setPage((prevPage) => prevPage + 1);
  }
  return (
    <div>
      <div className="text-[#98A2B3] flex flex-col gap-y-2 lg:gap-y-4 mb-4">
        <h4 className="font-bold">Select Duration:</h4>
        <div className="flex flex-wrap gap-x-10 gap-y-4 items-center justify-center">
          {durations.map(({ name, value }, index) => (
            <button
              key={index}
              className={`text-fedacash-header font-semibold px-4 py-2 text-lg rounded-full capitalize focus:border-none transition-all ${
                selectedDuration === value ? "bg-[#DECFED]" : "bg-[#F6F8FA]"
              }`}
              onClick={() => handleDurationClick(value)}
            >
              {name}
            </button>
          ))}
        </div>
      </div>
      <p className="text-red-700"> {error}</p>
      <button
        onClick={validatefrequencyDuration}
        className="w-full bg-fedacash-header px-2 py-[10px] text-white rounded-md text-center"
      >
        Continue
      </button>
    </div>
  );
};

const MultiStepForm = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { flashToast } = useContext(ToastContext);

  const navigate = useNavigate();
  function closeModal() {
    setIsOpen(false);
  }
  function openModal(name) {
    setIsOpen(true);
  }
  //the page number, starts at 0 and ends at 3
  const [page, setPage] = useState(0);
  //state for the api response message
  const [message, setMessage] = useState({});
  //our values from the multistep form
  const [values, setValues] = useState({
    description: "",
    amount: "",
    frequencySelection: "",
    frequencyDuration: "",
  });
  //state for the checkbox
  const [checked, setChecked] = useState(true);
  //state to control the status modal
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  //functions to move the page next and previous
  const nextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };
  const prevPage = () => {
    setPage((prevPage) => prevPage - 1);
  };
  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  //function  to open status modal
  function closeStatusModal() {
    setIsStatusOpen(false);
    setChecked(false);
  }
  function openStatusModal() {
    setIsStatusOpen(true);
  }

  const conditionalComponent = () => {
    switch (page) {
      case 0:
        return (
          <Description
            value={values.description}
            nextPage={nextPage}
            onChange={handleChange}
          />
        );
      case 1:
        return (
          <Amount
            value={values.amount}
            onChange={handleChange}
            nextPage={nextPage}
          />
        );
      case 2:
        return (
          <FrequencySelection
            amountValue={values.amount}
            onChange={handleChange}
            nextPage={nextPage}
          />
        );
      case 3:
        return (
          <FrequencyDuration
            amountValue={values.amount}
            onChange={handleChange}
            nextPage={nextPage}
          />
        );
      case 4:
        return <Summary values={values} onChange={handleChange} />;
      default:
        return null;
    }
  };

  const paystackCharges =
    values.amount > 1999 ? values.amount * 0.015 + 100 : values.amount * 0.015;
  const finalAmount = parseFloat(values.amount) + parseFloat(paystackCharges);
  // console.log(finalAmount)

  const config = {
    reference: new Date().getTime().toString(),
    email:
      UserObject.getUser().email ??
      `${UserObject.getUser().phone_number}@fedacash.com`,
    amount: parseInt(finalAmount * 100), //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey:
      process.env.REACT_APP_VERCEL_ENV !== "production"
        ? process.env.REACT_APP_TEST_KEY
        : process.env.REACT_APP_LIVE_KEY,
    channels: ["card"],
    metadata: {
      module: "cooperative",
      product: "save and go",
      payment_name: "Wallet Funding",
      payment_description: "Save and go wallet funding",
      customer_email: UserObject.getUser().email ?? "",
      phone_number: UserObject.getUser().phone_number ?? "",
    },
  };
  // you can call this function anything
  const onSuccess = (reference) => {
    setIsLoading(false);
    // Implementation for whatever you want to do with reference and after success call.
    JoinPlan({
      frequency_selection: values.frequencySelection,
      frequency_duration: parseInt(values.frequencyDuration),
      description: values.description,
      amount: values.amount,
      reference: reference.reference,
      payment_option: "card",
    })
      .then((res) => {
        setMessage(res);
        flashToast(res?.success, res?.message ?? "successful");
        closeModal();
      })
      .catch((res) => {
        flashToast(res?.success, res?.message ?? "failed");
      })
      .finally(() => {
        navigate(".");
        // console.log("done")
      });
  };
  const WalletPayment = () => {
    setIsLoading(true);
    JoinPlan({
      frequency_selection: values.frequencySelection,
      frequency_duration: parseInt(values.frequencyDuration),
      description: values.description,
      amount: values.amount,
      reference: "wallet payment",
      payment_option: "wallet",
    }).then((res) => {
      setIsLoading(false);
      console.log(res);
      setMessage(res);
      openStatusModal();
      closeModal();
    });
  };
  // you can call this function anything
  const onPaystackClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log("closed");
  };
  const initializePayment = usePaystackPayment(config);

  return (
    <>
      <button
        onClick={openModal}
        className="flex items-center gap-2 mt-2 text-sm bg-black p-2 text-white rounded-md"
      >
        <AiOutlinePlus />
        <span>Join Cooperative</span>
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 rounded-md"
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto rounded-md">
            <div className="flex min-h-full items-center justify-center p-4 text-center rounded-md">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg min-h-[50%] transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex gap-3 justify-evenly text-lg font-bold leading-6 text-[#475467]"
                  >
                    <span>Start Saving with our FedaCash Cooperative</span>
                    <button type="button" className="" onClick={closeModal}>
                      <AiOutlineClose />
                    </button>
                  </Dialog.Title>
                  <div className="flex justify-center gap-x-5">
                    {page > 0 && (
                      <button onClick={prevPage}>
                        <AiOutlineLeft size={30} />
                      </button>
                    )}
                    <div className="flex flex-col justify-center items-center">
                      <img src={paymentImage} alt="" />
                    </div>
                  </div>

                  <Form onSubmit={(event) => event.preventDefault()}>
                    {conditionalComponent()}
                    <div className="mt-8">
                      {page < 0 || page <= 3 ? (
                        ""
                      ) : (
                        <div className="space-y-2">
                          <div className="flex items-center gap-x-3 mt-3">
                            <input
                              type="checkbox"
                              className="rounded-sm"
                              value={checked}
                              onChange={() => setChecked((prev) => !prev)}
                            />
                            . I agree to be debited instantly for this
                            Contribution
                          </div>
                          <button
                            onClick={() => {
                              setIsLoading(true);
                              initializePayment(onSuccess, onPaystackClose);
                            }}
                            disabled={checked}
                            className="w-full bg-fedacash-header px-2 py-[10px] text-white rounded-md text-center"
                          >
                            Proceed with paystack
                          </button>
                          <button
                            disabled={checked}
                            onClick={() => WalletPayment()}
                            className="w-full bg-fedacash-header px-2 py-[10px] text-white rounded-md text-center"
                          >
                            {!isLoading && "Pay with wallet"}
                            {isLoading && (
                              <div className="flex items-center justify-center">
                                <Spinner
                                  color="info"
                                  aria-label="It is loading"
                                />
                                <span className="ml-2">Please wait...</span>
                              </div>
                            )}
                          </button>
                        </div>
                      )}
                    </div>
                  </Form>

                  {/* Display the form values for debugging purposes */}
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <StatusDialog
        closeModal={closeStatusModal}
        isOpen={isStatusOpen}
        message={message}
      />
    </>
  );
};

const FundWallet = () => {
  // const queryClient = useQueryClient();
  const { flashToast } = useContext(ToastContext);

  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState("idle"); //three status - idle/ processing/completed
  const [deposit, setDeposit] = useState("");
  const [message, setMessage] = useState({});
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const navigate = useNavigate();
  function closeStatusModal() {
    setIsStatusOpen(false);
  }

  function openStatusModal() {
    setIsStatusOpen(true);
  }
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  async function fundWallet(data) {
    try {
      let response = await http.post("/cooperative/add/saveandgo/wallet", data);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }
  const paystackCharges =
    deposit > 1999 ? deposit * 0.015 + 100 : deposit * 0.015;
  const finalAmount = parseFloat(deposit) + parseFloat(paystackCharges);
  const config = {
    reference: new Date().getTime().toString(),
    email:
      UserObject.getUser().email ??
      `${UserObject.getUser().phone_number}@fedacash.com`,
    amount: parseInt(finalAmount * 100), //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey:
      process.env.REACT_APP_VERCEL_ENV !== "production"
        ? process.env.REACT_APP_TEST_KEY
        : process.env.REACT_APP_LIVE_KEY,
    metadata: {
      product: "wallet",
      payment_name: "wallet_funding",
      module: "wallet",
      payment_description: "funding for wallet",
      customer_email: UserObject.getUser().email ?? "",
      phone_number: UserObject.getUser().phone_number ?? "",
    },
  };

  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    // console.log(reference);
    fundWallet({
      reference: reference?.reference,
      amount: parseFloat(deposit),
    })
      .then((res) => {
        setMessage(res);
        // queryClient.invalidateQueries("saveandgo")
        flashToast(res?.success, res?.message ?? "failed");
        closeModal();
        navigate(".");
      })
      .catch((res) => {
        flashToast(res?.success, res?.message ?? "failed");
      })
      .finally(() => {
        navigate(".");
      });
  };

  // you can call this function anything
  const onPaystackClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log("closed");
  };
  const initializePayment = usePaystackPayment(config);
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (message?.success) {
  //       // Refresh the page with React Router in a few seconds
  //       window.location.reload();
  //     }
  //   }, 3000);
  //   return () => clearTimeout(timer);
  // }, [message?.success]);

  return (
    <div>
      <button
        onClick={openModal}
        name="fund wallet"
        className="flex items-center py-2 px-5 space-x-2 bg-[#120321] text-white rounded-lg  font-semibold text-xs md:text-sm  leading-[21px] "
      >
        <AiOutlinePlus />
        <span>Fund</span>
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full rounded-md max-w-xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex gap-3 justify-evenly text-lg font-bold leading-6 text-[#475467]"
                  >
                    Fund Your Wallet
                    <button
                      type="button"
                      className=""
                      onClick={() => closeModal()}
                    >
                      <AiOutlineClose />
                    </button>
                  </Dialog.Title>
                  <div className="flex flex-col items-center justify-center">
                    <img src={walletimage} alt="" />
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        initializePayment(onSuccess, onPaystackClose);
                      }}
                      className="py-4 space-y-3"
                    >
                      <div>
                        <label>Enter Amount</label>
                        <input
                          placeholder="Amount"
                          value={deposit}
                          required
                          type="number"
                          onChange={(e) => setDeposit(e.target.value)}
                          className="font-bold w-full text-[12px] leading-[18px] py-[11px] px-[19px] placeholder:text-[#b8c0cc] border-[1.5px] rounded-[8px] border-[#b8c0cc] focus:outline-none"
                        />
                      </div>
                      <div className="mt-4">
                        <button className="text-white bg-fedacash-header text-center w-full py-2 md:py-3 md:px-2 rounded-md text-sm lg:textbase">
                          Proceed to payment
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <StatusDialog
        closeModal={closeStatusModal}
        isOpen={isStatusOpen}
        message={message}
      />
    </div>
  );
};
