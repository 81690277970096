import UserObject from "../../../DataObjects/UserObject";
import { useState, Fragment, Suspense, useEffect } from "react";
import { BiPaperPlane } from "react-icons/bi";
import { Tab, Dialog, Transition } from "@headlessui/react";
import { useFetcher, useNavigate } from "react-router-dom";
import useForm from "../../../Hooks/useForm";
import { AiOutlineClose } from "react-icons/ai";
import Banks from "../../../Pages/App/Dashboard/Banks";
import walletimage from "../../../Assets/dashboard/EmptyStatewallet.svg";
import { StatusDialog } from "../Shared/StatusDialog";
import Submit from "../../Forms/Submit";
import {
  InitiateWithdrawal,
  VertifyPassword,
} from "../../../Services/Dashboard/WithdrawService";
import { Link } from "react-router-dom";
import http from "../../../Store/baseHttp";
import ResponseObject from "../../../DataObjects/ResponseObject";

const WithdrawlForm = () => {
  const { Form } = useFetcher();
  //the page number, starts at 0 and ends at 3
  const [page, setPage] = useState(0);
  //state for the modal
  const [isWithdrawalOpen, setIsWithdrawalOpen] = useState(false);
  const [bankDetails, setBankDetails] = useState(null);

  //state for the api response message
  const [message, setMessage] = useState({});
  //our values from the multistep form
  const [formValues, setFormValues] = useState({
    account_number: "",
    amount: "",
    bank_name: "",
    password: "",
  });
  // const [bankCode, setBankCode] = useState("");
  const toggleWithdrawalModal = () => {
    setIsWithdrawalOpen((prev) => !prev);
    setPage(0);
  };
  const nextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };
  const handleChange = (name, value) => {
    // console.log(name, value);
    setFormValues({ ...formValues, [name]: value });
  };
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  function closeStatusModal() {
    setIsStatusOpen(false);
  }
  function openStatusModal() {
    setIsStatusOpen(true);
  }
  const conditionalComponent = () => {
    switch (page) {
      case 0:
        return (
          <AmountForm
            handleChange={handleChange}
            amount={formValues.amount}
            nextPage={nextPage}
          />
        );
      case 1:
        return (
          <BankDetailsForm
            handleChange={handleChange}
            formValues={formValues}
            nextPage={nextPage}
            setFormValues={setFormValues}
          />
        );
      case 2:
        return (
          <PasswordForm
            handleChange={handleChange}
            form={formValues}
            setMessage={setMessage}
            openStatusModal={openStatusModal}
            closeModal={toggleWithdrawalModal}
            bankCode
          />
        );

      default:
        return null;
    }
  };
  const [userBankDetails, setUserBankDetails] = useState([]);

  useEffect(() => {
      const fetchBankDetails = async () => {
          let details = await http.get("/user/list-bank-details");
          details = ResponseObject.from(details);
          // console.log(details)
          setBankDetails(details?.data?.bank_object);
      };
      fetchBankDetails()
  }, []); 

  return (
    <div>
      <button
        onClick={toggleWithdrawalModal}
        className="flex items-center py-2 px-5 space-x-2 bg-[#120321] text-white rounded-lg font-semibold text-xs md:text-sm leading-[21px] "
      >
        <BiPaperPlane className="w-6 h-6 " />
        <span>Withdraw</span>
      </button>
      <Transition appear show={isWithdrawalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={toggleWithdrawalModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full rounded-md max-w-xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex gap-3 justify-evenly text-lg font-bold leading-6 text-[#475467]"
                  >
                   Withdrawal
                    <button
                      type="button"
                      className=""
                      onClick={() => toggleWithdrawalModal()}
                    >
                      <AiOutlineClose />
                    </button>
                  </Dialog.Title>
                  <div className="flex flex-col items-center justify-center">
                    <img src={walletimage} alt="" />
                    <Form
                      onSubmit={(event) => event.preventDefault()}
                      className="py-4 space-y-3"
                    >
                      {/* <div className="flex gap-x-3 mt-3">
                          <input
                            type="checkbox"
                            value={checked}
                            onChange={() => setChecked((prev) => !prev)}
                          />
                          I agree to the FedaCash terms and condition
                        </div> */}
                      {conditionalComponent()}
                    </Form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <StatusDialog
        closeModal={closeStatusModal}
        isOpen={isStatusOpen}
        message={message}
      />
    </div>
  );
};
export default WithdrawlForm;

const AmountForm = ({ handleChange, nextPage, amount }) => {
  const [error, setError] = useState("");
  const minimumAmount = 2000;
  const formattedAmount = `₦${minimumAmount}`;
  const validateAmount = () => {
    if (amount === "") {
      setError("Amount is required.");
    } else if (parseInt(amount) < minimumAmount) {
      setError(`Minimum amount is ${formattedAmount}`);
    } else if (amount.includes("-")) {
      setError("You can't add a negative value");
    } else {
      nextPage();
    }
  };
  return (
    <div>
      <div>
        <label className="text-sm md:text-lg font-bold">Enter Amount</label>
        <input
          placeholder="Amount"
          name="amount"
          value={amount}
          onChange={(e) => handleChange("amount", e.target.value)}
          required
          type="number"
          className="font-bold w-full text-[12px] leading-[18px] py-[11px] px-[19px] placeholder:text-[#b8c0cc] border-[1.5px] rounded-[8px] border-[#b8c0cc] focus:outline-none"
        />
        <p className="text-red-700 text-xs md:text-sm py-2">{error}</p>
      </div>
      <div className="mt-4">
        <button
          onClick={validateAmount}
          className="text-white bg-fedacash-header text-center w-full py-2 md:py-3 md:px-2 rounded-md text-sm lg:textbase"
        >
          Next
        </button>
      </div>
    </div>
  );
};
const BankDetailsForm = ({
  nextPage,
  formValues,
  setFormValues
}) => {
  const [error, setError] = useState("");
  const [bankIndex, setBankIndex] = useState("");

  const { organization } = UserObject.getUser();
  const addBankDetails = (bank, index) => {
    setBankIndex(index);
    for (const key in bank) {
      if (Object.hasOwnProperty.call(bank, key)) {
        const value = bank[key];
        // Update state for each key-value pair in the bankObject
        setFormValues((formValue) => ({
          ...formValue,
          [key]: value,
        }));
      }
    }
  };
  return (
    <div>
      <div className="space-y-3 h-[200px] overflow-y-scroll">
        {organization.bank_details?.length ? (
          organization.bank_details.map((bank, index) => (
            <div
              className={`flex flex-col items-start justify-start px-4 py-3 rounded-md ${
                bankIndex === index ? "bg-[#DECFED]" : "bg-[#F6F8FA]"
              }`}
              key={index}
              onClick={(e) => addBankDetails(bank, index)}
            >
              <p className="text-lg font-medium">{bank.account_number}</p>
              <p className="">{bank.bank_name}</p>
              <p className="">{bank?.account_name}</p>
            </div>
          ))
        ) : (
          <div>
            <p>You haven't added any account with us</p>
            <p>
              Go to your{" "}
              <Link
                to={`/dashboard/settings`}
                className="text-fedacash-header font medium cursor-pointer"
              >
                profile
              </Link>{" "}
              to add
            </p>
          </div>
        )}
      </div>

      <p className="text-red-700 text-xs md:text-sm py-2">{error}</p>
      <div className="mt-4">
        <button
          onClick={() => {
            if (
              formValues.account_number === "" ||
              formValues.bankname == "" ||
              formValues.account_number === ""
            ) {
              setError("Please select an account from this list");
            } else {
              nextPage();
            }
          }}
          className="text-white bg-fedacash-header text-center w-full py-2 md:py-3 md:px-2 rounded-md text-sm lg:textbase"
        >
          Next
        </button>
      </div>
    </div>
  );
};
const PasswordForm = ({
  handleChange,
  form,
  setMessage,
  openStatusModal,
  closeModal,
}) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const user = UserObject.getUser();
  const navigate = useNavigate();

  const submitWithdrawalForm = () => {
    setLoading(true);
    VertifyPassword(form.password).then((res) => {
      // console.log(res);
      if (res.passwordVertifyResponse.success) {
        InitiateWithdrawal({
          bank_code: form.bank_code,
          recipient_account_number: form.account_number,
          recipient_account_name: form.account_name,
          payout_amount: form.amount,
        }).then((res) => {
          // console.log(res);
          setLoading(false);
          setMessage(res.withdrawalResponse);
          openStatusModal();
          closeModal();
          navigate(".");
        });
      } else {
        setError(res.passwordVertifyResponse.errors);
        setLoading(false);
        // console.log(res.errors)
      }
    });
  };
  return (
    <div>
      <label className="text-sm md:text-lg font-bold">
        Enter Your Password
      </label>
      <input
        placeholder="Password"
        type="password"
        name="password"
        onChange={(e) => handleChange("password", e.target.value)}
        value={form.password}
        className="font-bold w-full text-[12px] leading-[18px] py-[11px] px-[19px] placeholder:text-[#b8c0cc] border-[1.5px] rounded-[8px] border-[#b8c0cc] focus:outline-none"
      />
      <p className="text-red-700 text-xs md:text-sm py-2">{error}</p>

      <div className="mt-4">
        <Submit
          isLoading={loading}
          onClick={() => {
            if (form.password === "") {
              setError("Please enter your password");
            } else {
              submitWithdrawalForm();
            }
          }}
        >
          Proceed to withdrawal
        </Submit>
      </div>
    </div>
  );
};
