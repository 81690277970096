import {
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import useToggler from "../Hooks/useToggler";
import NavBar from "../Components/Dashboard/NavBar";
import TopBar from "../Components/Dashboard/TopBar";
import ProtectedRoute from "../Routes/ProtectedRoute";
import { useEffect, useState } from "react";
import useIdle from "../Hooks/useIdle";
import UserObject from "../DataObjects/UserObject";
import Toast from "../Components/Dashboard/Notification/Toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Dashboard = () => {
  const [value, click] = useToggler(false);
  const [isLoading, setIsLoading] = useState(false);

  const isIdle = useIdle({ timeToIdle: 1.8e6, inactivityEvents: [] });
  const navigate = useNavigate();
  const navigation = useNavigation();

  const location = useLocation();

  useEffect(() => {
    if (isIdle) {
      UserObject.logOutUser();
      navigate("/auth/login");
    }
  }, [isIdle, navigate]);

  useEffect(() => {
    // Is this just a normal load?
    let isNormalLoad =
      navigation.state === "loading" && navigation.formData == null;

    // Are we reloading after an action?
    let isReloading =
      navigation.state === "loading" &&
      navigation.formData != null &&
      navigation.formAction === navigation.location.pathname;

    // Are we redirecting after an action?
    let isRedirecting =
      navigation.state === "loading" &&
      navigation.formData != null &&
      navigation.formAction !== navigation.location.pathname;
    if (isNormalLoad || isReloading || isRedirecting) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [navigation.state]);

  
  useEffect(() => {
    const scriptElement = document.createElement('script');
    scriptElement.async = true;
    scriptElement.src = 'https://embed.tawk.to/67289e652480f5b4f5985257/1ibralftd';
    scriptElement.charset = 'UTF-8';
    scriptElement.setAttribute('crossorigin', '*');
    document.body.appendChild(scriptElement);

    return () => {
      document.body.removeChild(scriptElement);
    };
  }, []);
  return (
    <ProtectedRoute>
      <Toast />
      <div className="font-montserrat relative flex items-start justify-start w-full h-screen bg-[#F7F8F9]">
        <NavBar value={value} click={click} />
        <div className="flex-1 flex flex-col items-start justify-start w-full h-screen">
          <TopBar value={value} click={click} />
          <div className="py-10 md:px-20 xl:px-28 px-4 overflow-y-auto w-full h-full">
            {isLoading && (
              <div className="absolute inset-0 bg-black bg-opacity-10 flex items-center justify-center z-[9999]">
                <AiOutlineLoading3Quarters
                  className="text-fedacash-dark animate-spin"
                  size={30}
                />
              </div>
            )}
            <Outlet />
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
};

export default Dashboard;

