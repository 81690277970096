import Input from "../../Components/Forms/Input";
import Password from "../../Components/Forms/Password";
import Submit from "../../Components/Forms/Submit";
import {
  Link,
  useFetcher,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import HeaderLogo from "../../Components/LandingPage/HeaderLogo";
import { useEffect } from "react";
import useTitle from "../../Hooks/useTitle";
import SocialLogin from "./SocialLogin";
import Countries from "../App/Dashboard/Countries";
import Categories from "../App/Dashboard/Categories";
// import intlTelInput from "intl-tel-input"
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
const Register = () => {
  useTitle("Register | FedaCash SMEs");
  let [searchParams, setSearchParams] = useSearchParams();
  const [selectedValue, setSelectedValue] = useState(false);

  const { data, Form, formAction, state, formData } = useFetcher();
  const navigate = useNavigate();
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
  function onChange(value) {
    setIsCaptchaSuccess(true);
    // console.log("captcha value: ", value);
  }
  // Define a function to handle the change event of the radio inputs
  const handleRadioChange = (e) => {
    //get the value from the radio clsme input, check if it is 1 reutrn true or 0 return false
    const CLSMEMemberValue = e.target.value === "1" ? true : false
    // Set the state to the new value
    setSelectedValue(CLSMEMemberValue);
  };
  useEffect(() => {
    // console.log(data);
    if (data?.success) {
      if (data?.data.email) {
        navigate({
          pathname: "/auth/verify",
          search: "email=" + data?.data.email,
        });
        return; // this will end the function after navigating to the email verification page
      }
      if (data?.data.phone_number) {
        navigate({
          pathname: "/auth/verify",
          search: "phone=" + data?.data.phone_number,
        });
        return; // this will end the function after navigating to the phone verification page
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data]);

  // console.log(process.env.REACT_APP_RECAPTCHA_SITE_KEY);
  return (
    <div className="w-full h-full pb-20">
      <HeaderLogo />

      <div className="my-10">
        <p className="text-gray-800" style={{ fontSize: "18px" }}>
          Already have an account{" "}
          <Link to="/auth/login" className=" text-purple-900 font-semibold">
            Login
          </Link>
        </p>
      </div>
      <h1 className="mb-8 mt-14" style={{ fontSize: "44px", fontWeight: 800 }}>
        Get Started
      </h1>

      <SocialLogin isRegister={true}>Get Started with Google</SocialLogin>

      <div className="w-full flex items-center justify-between space-x-4 overflow-hidden">
        <hr className="w-full" />{" "}
        <p className="uppercase inline-block text-center font-light text-gray-700">
          OR
        </p>
        <hr className="w-full" />
      </div>
      <Form method="post" action={formAction}>
        <Input
          label="First Name"
          name="first_name"
          normalize={true}
          placeholder="Enter your first name"
          error={data?.errors?.first_name}
        />
        <Input
          label="Last Name"
          name="last_name"
          normalize={true}
          placeholder="Enter your last name"
          error={data?.errors?.last_name}
        />
        <Input
          label="Phone Number"
          name="phone_number"
          placeholder="Enter your Phone Number"
          error={data?.errors?.phone_number}
        />
        <Input
          label="Email address"
          name="email"
          placeholder="Enter your email"
          error={data?.errors?.email}
        />
        {/* <Input
                    label="Business Name"
                    name="company_name"
                    normalize={true}
                    placeholder="Enter your business name"
                    error={data?.errors?.company_name}
                /> */}
        <div className="flex flex-col gap-y-3 my-2">
          <h3 className="mb-1 capitalize text-gray-700 w-full text-lg font-bold">
            Are you a CLSME member:
          </h3>
          <div className="flex gap-x-2 text-base">
            <label>
              <input
                type="radio"
                name="clsme_member_status"
                value="1"
                checked={selectedValue === true}
                onChange={handleRadioChange}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="clsme_member_status"
                value="0"
                checked={selectedValue === false}
                onChange={handleRadioChange}
              />
              No
            </label>
          </div>
          {/* <p className="text-red-600 font-bold text-sm">{data?.errors?.clsme_member_status}</p> */}
        </div>
        <Password
          label="password"
          name="password"
          placeholder="Enter new Password"
          error={data?.errors?.password}
        />
        {/* <Countries label="Country of Operation" name="country_of_operation" placeholder="Country" error={data?.errors?.country_of_operation} />
                <span className="inline-block mt-4"></span>
                <Categories top={true} label="Business Category" name='business_category' error={data?.errors?.business_category} /> */}
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={onChange}
        />
        <Submit 
        isLoading={state !== "idle"} 
        disabled={!isCaptchaSuccessful}
        >
          Get Started
        </Submit>
      </Form>
    </div>
  );
};

export default Register;
