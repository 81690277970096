import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiOutlineTwitter,
} from "react-icons/ai";
import LogoFooter from "../../../Assets/logo/LogoFooter.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section className="xl:px-2 md:px-6 px-8 container mx-auto lg:py-12">
      {/* mainfooter */}
      <section className="flex justify-between gap-x-3 gap-y-4 py-12 flex-wrap lg:flex-nowrap">
        <div className="flex flex-col gap-3">
          <img
            className="lg:w-[182.38px] w-[136.38px]"
            src={LogoFooter}
            alt="logo"
          />
          <div className="self-stretch text-black text-base font-normal leading-normal">
            Join our newsletter to stay up to date on features and releases.
          </div>
          <div className="self-stretch justify-start items-start gap-4 inline-flex">
            <div className="">
              <input
                className="lg:w-[360px] w-[200px] self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 flex"
                placeholder="Enter your email"
              />
            </div>
            <div className="h-[52px] px-2.5 py-4 bg-fedacash-header rounded-lg justify-center items-center gap-2 flex cursor-pointer">
              <div className="text-white md:text-base text-sm font-medium">
                Subscribe
              </div>
            </div>
          </div>
          <div className="self-stretch text-black text-xs font-normal leading-[18px]">
            By subscribing you agree to with our Privacy Policy and provide
            consent to receive updates from our company.
          </div>
        </div>
        <div className="flex md:flex-row flex-col flex-wrap gap-4 lg:justify-evenly justify-between w-full">
          <div className="flex-col justify-start items-start gap-4 inline-flex">
            <div className="self-stretch text-black text-base font-semibold leading-normal">
              Our Services
            </div>
            <div className="self-stretch h-[148px] flex-col justify-start items-start flex">
              <div
                className="self-stretch flex-col justify-start items-start flex capitalize
              "
              >
                {[
                  ["Invoice Discounting", "services/invoice-discount"],
                  ["Cooperative", "services/cooperative"],
                  ["generate Invoice", "services/generate-invoice"],
                ].map(([name, link], index) => (
                  <div
                    className="self-stretch py-2 justify-start items-start inline-flex"
                    key={index}
                  >
                    <Link
                      className="grow shrink basis-0 text-black text-sm font-normal leading-[21px]"
                      to={link}
                    >
                      {name}
                    </Link>
                  </div>
                ))}
              </div>
              <div className="self-stretch py-2" />
            </div>
          </div>
          <div className="flex-col justify-start items-start gap-4 inline-flex">
            <div className="self-stretch text-black text-base font-semibold leading-normal">
             About Us
            </div>
            <div className="self-stretch flex-col justify-start items-start flex">
              {[
                ["About Us", "about"],
                ["Our Target Market", "#target-market"],
                ["Contact Us", "contact"],
              ].map(([name, link, logo], index) => (
                <div
                  className="self-stretch py-2 justify-start items-start inline-flex"
                  key={index}
                >
                  <Link
                    className="grow shrink basis-0 text-black text-sm font-normal leading-[21px]"
                    to={link}
                  >
                    {name}
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="flex-col justify-start items-start gap-4 inline-flex">
            <div className="self-stretch text-black text-base font-semibold leading-normal">
              Our Social Media Pages
            </div>
            <div className="self-stretch h-[148px] flex-col justify-start items-start flex">
              {[
                [
                  "Facebook",
                  "/",
                  <AiFillFacebook className="w-6 h-6 " color="#590DA4" />,
                ],
                [
                  "Instagram",

                  "https://www.instagram.com/fedacashng/",
                  <AiFillInstagram className="w-6 h-6 " color="#590DA4" />,
                ],
                [
                  "Twitter",
                  "https://twitter.com/FedaCash",
                  <AiOutlineTwitter className="w-6 h-6" color="#590DA4" />,
                ],
                [
                  "LinkedIn",
                  "https://www.linkedin.com/company/fedhagap",
                  <AiFillLinkedin className="w-6 h-6 " color="#590DA4" />,
                ],
              ].map(([name, link, logo], index) => (
                <div
                  className="w-[180px] h-10 py-2 justify-start items-start gap-3 inline-flex"
                  key={index}
                >
                  {logo}

                  <Link
                    className="text-black text-sm font-normal leading-[21px]"
                    to={link}
                    target="_blank"
                  >
                    {name}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <div className="self-stretch h-[54px] flex-col justify-start items-start gap-8 flex">
        <div className="self-stretch h-px bg-black" />
        <div className="self-stretch justify-between items-start gap-y-8 gap-x-16 flex flex-wrap lg:flex-nowrap">
          <div className="text-black text-sm font-normal leading-[21px]">
            2023 FedaCash. All right reserved.
          </div>
          <div className="justify-start text-xs md:text-sm items-start gap-6 flex cursor-pointer">
            <div className="text-black font-normal underline leading-[21px]">
              <Link to="privacy-policy"> Privacy Policy</Link>
            </div>
            <div className="text-black font-normal underline leading-[21px]">
              <Link to="terms-and-conditions"> Terms of Service</Link>
            </div>
            <div className="text-black font-normal underline leading-[21px]">
              <Link to="eligibility-and-criterial"></Link>
              Eligibility
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
